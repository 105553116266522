html,
body {
  height: 100%;
}

.root {
  height: inherit;
}

body {
  margin: 0;
  font-family: "Noto Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "SFProDisplay";
  src: local("SFProDisplay"),
    url("./font/SF-Pro-Display-Regular.otf") format("truetype");
}
@font-face {
  font-family: "SFProDisplayBold";
  src: local("SFProDisplay"),
    url("./font/FontsFree-Net-SFProDisplay-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "SFProDisplayMedium";
  src: local("SFProDisplay"),
    url("./font/SF-Pro-Display-Medium.otf") format("truetype");
}
* {
  font-family: SFProDisplayMedium !important;
  /* Add !important to overwrite all elements */
}
